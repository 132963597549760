<template>
  <main>
    <section class="container-fluid">
      <Navbar black active="news" :loading="loading" />
    </section>
    <div class="services container mt-5">
      <div class="row">
        <div class="col">
          <h2>
            Новости
          </h2>
        </div>
      </div>
      <div class="row gx-5">
        <div class="col-sm-4 mt-5" v-for="(item, index) in news" :key="`item-${index}`">
          <div class="card" @click="goToUrl(item.link)">
            <!-- <img src="../assets/index/about.png" class="card-img-top" alt="..." /> -->
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text mt-3">
                {{ item.description }}
              </p>
              <div class="card-date">{{ item.date }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        Источник:
        {{ channel.title }}
        <br />
        {{ channel.link }}
      </div>
      <!-- <div class="row mt-5">
        <nav aria-label="Page navigation example ">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item "><a class="page-link active" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div> -->
    </div>
    <Footer />
  </main>
</template>

<script>
import API from '@/api';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import parser from 'fast-xml-parser';

export default {
  metaInfo() {
    const title = this.$t('mainTitle');
    return {
      title,
      titleTemplate: '%s | atcbrok.kz'
    };
  },
  name: 'Services',
  components: {
    Navbar,
    Footer
  },
  data: () => ({
    news: null,
    channel: null
  }),
  created() {
    this.getNews();
  },
  methods: {
    async getNews() {
      try {
        this.loading = true;

        const response = await API.getNews();

        var feed = parser.parse(response);

        if (!this.news) {
          this.news = [];
        }

        this.channel = {
          title: feed.rss.title,
          link: feed.rss.channel.link
        };

        feed.rss.channel.item.forEach(item => {
          if (this.news.length < 12) {
            const news = {
              title: item.title.replace(
                /(S&amp;)|(&quot;)|(&lt;p&gt;)|(&lt;\/p&gt;)|(&amp;nbsp;)/gm,
                ''
              ),
              description:
                item.description
                  .replace(/(S&amp;)|(&quot;)|(&lt;p&gt;)|(&lt;\/p&gt;)|(&amp;nbsp;)/gm, '')
                  .slice(0, 250) + '...',
              link: item.link,
              date: item.pubDate
            };

            this.news.push(news);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    goToUrl(link) {
      window.location.href = link;
    }
  }
};
</script>

<style scoped lang="scss">
.services {
  &__paragraph {
    font-size: 1.25rem;
  }

  .card {
    background-color: transparent;
    border: none;
    cursor: pointer;

    &-body {
      padding: 1.5rem 0 0 0;
    }

    &-title {
      font-family: 'Fira Sans', sans-serif;
    }

    &-date {
      font-size: 1.125rem;
      color: #cccccc;
      font-weight: 400;
    }
  }

  .pagination {
    .page-item {
      .active {
        background-color: #f7c723 !important;
      }

      .page-link {
        padding: 1rem 1.7rem;
        margin-right: 1rem;
        color: black;
        background-color: white;
        border-radius: 50%;
        font-size: 1.375rem;
      }
    }
  }
}
</style>
